module.exports = {
    getNurseDisplayType(nurseConfigType) {
        let keyword = 'NURSE';
        if (nurseConfigType.includes(keyword)) {
            let index = nurseConfigType.indexOf(keyword);
            let prefix = nurseConfigType.slice(0, index).trim();
            let suffix = nurseConfigType.slice(index + keyword.length).trim();
            let splitStr = `${prefix} ${keyword} ${suffix}`;
            return splitStr.split(' ').map((word) => {
                const firstChar = word.charAt(0);
                const restOfWord = word.slice(1).toLowerCase();
                return firstChar.toUpperCase() + restOfWord;
            }).join(' ');
        } else {
            return nurseConfigType;
        }
    }
}