<template>
    <div class="parentClass">
        <div v-if="loader">
            <span>loading...</span>
        </div>
        <div v-else>
            <div style="margin-bottom: 2.5rem" v-if="activeAppointmentDetails.length > 0">
                <p>Today Appointments</p>
                <div v-for="appointmentDetail of activeAppointmentDetails"
                    :key="appointmentDetail.bookingID" style="margin-bottom: 24px">
                    <appointmentsCard appointmentType="ACTIVE"
                        :appointmentDetail="appointmentDetail"></appointmentsCard>
                </div>
            </div>
            <p v-if="upcomingAppointmentDetails.length > 0">Upcoming Appointments</p>
            <div v-if="upcomingAppointmentDetails.length > 0" v-for="appointmentDetail of upcomingAppointmentDetails"
                :key="appointmentDetail.bookingID" style="margin-bottom: 24px">
                <appointmentsCard appointmentType="UPCOMING"
                    :appointmentDetail="appointmentDetail"></appointmentsCard>
            </div>
            <div v-if="activeAppointmentDetails.length === 0 && upcomingAppointmentDetails.length === 0" class="bookingsUnavailable">
                <img src="https://mymedicineuatimages.s3.ap-south-1.amazonaws.com/appointmentsNotFound.svg" />
                <h4>Bookings Unavailable!</h4>
                <span>No results found, Select your nurse and book appointment to see your upcoming bookings.</span>
                <v-btn outlined color="#48ACEF" width="100%" style="margin-top: 24px;" @click="gotoHome()">Go Home</v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import { axios_auth_instance_nurseBooking } from '../../utils/axios_utils';
import { getNurseDisplayType } from '../../helpers/nba.helper.js';
import appointmentsCard from './appointmentsCard.vue';
export default {
    name: 'completedAppointments',
    components: { appointmentsCard },
    data() {
        return {
            activeAppointmentDetails: [],
            upcomingAppointmentDetails: [],
            loader: false
        }
    },
    async mounted(){
        try {
            this.loader = true
            let fetchUpcomingAppointmentResponse = await axios_auth_instance_nurseBooking.get("/pagewise/upcomingAppointments")
            this.activeAppointmentDetails = fetchUpcomingAppointmentResponse.data.data.activeAppointments
            this.upcomingAppointmentDetails = fetchUpcomingAppointmentResponse.data.data.upcomingAppointments

            let activeAppointmentsFormattedArray = [];
            this.activeAppointmentDetails.forEach((appointment) => {
                let appointmentObject = {
                    ...appointment,
                    nurseDisplayStatus: getNurseDisplayType(appointment.nurse_type),
                };
                activeAppointmentsFormattedArray.push(appointmentObject);
            });
            this.activeAppointmentDetails = activeAppointmentsFormattedArray;

            let upcomingAppointmentsFormattedArray = [];
            this.upcomingAppointmentDetails.forEach((appointment) => {
                let appointmentObject = {
                    ...appointment,
                    nurseDisplayStatus: getNurseDisplayType(appointment.nurse_type),
                };
                upcomingAppointmentsFormattedArray.push(appointmentObject);
            });
            this.upcomingAppointmentDetails = upcomingAppointmentsFormattedArray;            

            this.loader = false
        } catch (error) {
            console.log("fetchAllBookingPageDetails Error:",error);
            if (error.response.status === 404) {
                this.activeAppointmentDetails = []
                this.upcomingAppointmentDetails = []
            }
            if (error.response.status === 401) {
                this.$cookies.remove('customerToken');
                this.$router.push({
                    name: "Login",
                });
            }
        }
    }
}
</script>

<style scoped>
.parentClass {
    display: flex;
    flex-direction: column;
    gap: 16px;
    text-align: flex-start;
}

span {
    color: #828282 !important;
    font-size: 14px;
}

.parentClass p{
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    color: #333 !important;
}

.bookingsUnavailable {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}
</style>